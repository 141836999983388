// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

.action-buttons-inner{
    display: flex;
}

a.item{
    display: flex;
    width: 20px;
    height: 20px;margin: 5px;
}
